<template>
  <g>
    <svg:style>
      .cls-10, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
      }

      .cls-3 {
        fill: url(#inox-gradient);
      }

      .cls-4 {
        fill: url(#inox-gradient-2);
      }

      .cls-5 {
        fill: url(#inox-gradient-3);
      }

      .cls-6 {
        fill: url(#inox-gradient-4);
      }

      .cls-7 {
        fill: url(#glass-pattern);
      }

      .cls-8 {
        fill: url(#glass-pattern);
      }

      .cls-9 {
        fill: url(#glass-pattern);
      }

      .cls-10 {
        fill: url(#glass-pattern);
      }

    </svg:style>
    <linearGradient id="inox-gradient" 
      :x1="doorLeftWidth1 + 56.62" 
      :y1="doorTopHeight1 + 178.5" 
      :x2="doorLeftWidth1 + 84.87" 
      :y2="doorTopHeight1 + 178.5" 
      gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stop-color="#babbbc"/>
      <stop offset="0.29" stop-color="#989a9e"/>
      <stop offset="0.65" stop-color="#bdbdc1"/>
      <stop offset="0.87" stop-color="#9d9fa2"/>
      <stop offset="1" stop-color="#a6a8aa"/>
      <stop offset="1" stop-color="#c2c5c9"/>
    </linearGradient>
    <linearGradient id="inox-gradient-2" data-name="inox" 
      :x1="doorLeftWidth1 + 56.62" 
      :y1="doorTopHeight1 + 122" 
      :x2="doorLeftWidth1 + 84.87" 
      :y2="doorTopHeight1 + 122" 
      xlink:href="#inox-gradient"/>
    <linearGradient id="inox-gradient-3" data-name="inox" 
      :x1="doorLeftWidth1 + 56.62" 
      :y1="doorTopHeight1 + 65.51" 
      :x2="doorLeftWidth1 + 84.87" 
      :y2="doorTopHeight1 + 65.51" 
      xlink:href="#inox-gradient"/>
    <linearGradient id="inox-gradient-4" data-name="inox" 
      :x1="doorLeftWidth1 + 56.62" 
      :y1="doorTopHeight1 + 234.99" 
      :x2="doorLeftWidth1 + 84.87" 
      :y2="doorTopHeight1 + 234.99" 
      xlink:href="#inox-gradient"/>
    <linearGradient id="glass-gradient" 
      :x1="doorLeftWidth1 + 70.75" 
      :y1="doorTopHeight1 + 74.75" 
      :x2="doorLeftWidth1 + 70.75" 
      :y2="doorTopHeight1 + 56.39" 
      gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#f4f4f4"/>
      <stop offset="0.5" stop-color="#dee8e8"/>
      <stop offset="1" stop-color="#d1cece"/>
    </linearGradient>
    <linearGradient id="glass-gradient-2" data-name="glass-gradient" 
      :x1="doorLeftWidth1 + 70.75" 
      :y1="doorTopHeight1 + 131.23" 
      :x2="doorLeftWidth1 + 70.75" 
      :y2="doorTopHeight1 + 112.88" 
      xlink:href="#glass-gradient"/>
    <linearGradient id="glass-gradient-3" data-name="glass-gradient" 
      :x1="doorLeftWidth1 + 70.75" 
      :y1="doorTopHeight1 + 187.73" 
      :x2="doorLeftWidth1 + 70.75" 
      :y2="doorTopHeight1 + 169.37" 
      xlink:href="#glass-gradient"/>
    <linearGradient id="glass-gradient-4" data-name="glass-gradient" 
      :x1="doorLeftWidth1 + 70.75" 
      :y1="doorTopHeight1 + 244.22" 
      :x2="doorLeftWidth1 + 70.75" 
      :y2="doorTopHeight1 + 225.86" 
      xlink:href="#glass-gradient"/>
    <linearGradient id="handle-gradient" 
      :x1="doorLeftWidth1 + 11.29" 
      :y1="doorTopHeight1 + 150.25" 
      :x2="doorLeftWidth1 + 16.94" 
      :y2="doorTopHeight1 + 150.25" 
      xlink:href="#inox-gradient"/>
    <filter id="inset-shadow">
      <feOffset dx="0" dy="0"/>
      <feGaussianBlur stdDeviation="1"  result="offset-blur"/>
      <feComposite operator="out" in="SourceGraphic" in2="offset-blur" result="inverse"/>
      <feFlood flood-color="black" flood-opacity="1" result="color"/>
      <feComposite operator="in" in="color" in2="inverse" result="shadow"/>
      <feComponentTransfer in="shadow" result="shadow">
        <feFuncA type="linear" slope="1.25"/>
      </feComponentTransfer>
      <feComposite operator="over" in="shadow" in2="SourceGraphic"/>
    </filter>
    <g id="S13">
      <polyline id="inox" 
                v-if="showInox"
                data-name="inox" 
                class="cls-3" 
                :points="`${doorLeftWidth1 + 56.62} ${doorTopHeight1 + 192.62} ${doorLeftWidth1 + 84.87} ${doorTopHeight1 + 192.62} ${doorLeftWidth1 + 84.87} ${doorTopHeight1 + 164.38} ${doorLeftWidth1 + 56.62} ${doorTopHeight1 + 164.38} ${doorLeftWidth1 + 56.62} ${doorTopHeight1 + 192.62}`"/>
      <polyline id="inox-2"
                v-if="showInox" 
                data-name="inox" 
                class="cls-4" 
                :points="`${doorLeftWidth1 + 56.62} ${doorTopHeight1 + 136.13} ${doorLeftWidth1 + 84.87} ${doorTopHeight1 + 136.13} ${doorLeftWidth1 + 84.87} ${doorTopHeight1 + 107.88} ${doorLeftWidth1 + 56.62} ${doorTopHeight1 + 107.88} ${doorLeftWidth1 + 56.62} ${doorTopHeight1 + 136.13}`"/>
      <polyline id="inox-3" 
                v-if="showInox"
                data-name="inox" 
                class="cls-5" 
                :points="`${doorLeftWidth1 + 56.62} ${doorTopHeight1 + 79.63} ${doorLeftWidth1 + 84.87} ${doorTopHeight1 + 79.63} ${doorLeftWidth1 + 84.87} ${doorTopHeight1 + 51.39} ${doorLeftWidth1 + 56.62} ${doorTopHeight1 + 51.39} ${doorLeftWidth1 + 56.62} ${doorTopHeight1 + 79.63}`"/>
      <polyline id="inox-4" 
                v-if="showInox"
                data-name="inox" 
                class="cls-6" 
                :points="`${doorLeftWidth1 + 56.62} ${doorTopHeight1 + 249.12} ${doorLeftWidth1 + 84.87} ${doorTopHeight1 + 249.12} ${doorLeftWidth1 + 84.87} ${doorTopHeight1 + 220.86} ${doorLeftWidth1 + 56.62} ${doorTopHeight1 + 220.86} ${doorLeftWidth1 + 56.62} ${doorTopHeight1 + 249.12}`"/>
      <polyline filter="url(#inset-shadow)" id="glass" 
                class="cls-7" 
                :points="`${doorLeftWidth1 + 79.93} ${doorTopHeight1 + 56.38} ${doorLeftWidth1 + 61.56} ${doorTopHeight1 + 56.38} ${doorLeftWidth1 + 61.56} ${doorTopHeight1 + 74.75} ${doorLeftWidth1 + 79.93} ${doorTopHeight1 + 74.75} ${doorLeftWidth1 + 79.93} ${doorTopHeight1 + 56.38}`"/>
      <polyline filter="url(#inset-shadow)" id="glass-2" 
                data-name="glass" 
                class="cls-8" 
                :points="`${doorLeftWidth1 + 79.93} ${doorTopHeight1 + 112.88} ${doorLeftWidth1 + 61.56} ${doorTopHeight1 + 112.88} ${doorLeftWidth1 + 61.56} ${doorTopHeight1 + 131.23} ${doorLeftWidth1 + 79.93} ${doorTopHeight1 + 131.23} ${doorLeftWidth1 + 79.93} ${doorTopHeight1 + 112.88}`"/>
      <polyline filter="url(#inset-shadow)" id="glass-3" 
                data-name="glass" 
                class="cls-9" 
                :points="`${doorLeftWidth1 + 79.93} ${doorTopHeight1 + 169.37} ${doorLeftWidth1 + 61.56} ${doorTopHeight1 + 169.37} ${doorLeftWidth1 + 61.56} ${doorTopHeight1 + 187.73} ${doorLeftWidth1 + 79.93} ${doorTopHeight1 + 187.73} ${doorLeftWidth1 + 79.93} ${doorTopHeight1 + 169.37}`"/>
      <polyline filter="url(#inset-shadow)" id="glass-4" 
                data-name="glass" 
                class="cls-10" 
                :points="`${doorLeftWidth1 + 79.93} ${doorTopHeight1 + 225.86} ${doorLeftWidth1 + 61.56} ${doorTopHeight1 + 225.86} ${doorLeftWidth1 + 61.56} ${doorTopHeight1 + 244.22} ${doorLeftWidth1 + 79.93} ${doorTopHeight1 + 244.22} ${doorLeftWidth1 + 79.93} ${doorTopHeight1 + 225.86}`"/>
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
  ],
  computed: {
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 71
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 153
    },
  }
}
</script>
